import React from 'react';

const FacebookLogo = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
    <defs />
    <path d='M16 3.98h2.19V.17a28.3 28.3 0 00-3.2-.17c-3.15 0-5.32 1.99-5.32 5.64V9H6.2v4.27h3.48V24h4.28V13.27h3.34L17.82 9h-3.87V6.06c0-1.23.33-2.08 2.05-2.08z' />
  </svg>
);

export default FacebookLogo;
