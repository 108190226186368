import { useEffect, useLayoutEffect } from 'react';
import { isClient } from '../utils';

/**
 * useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer's output format.
 *
 * This will lead to a mismatch between the initial, non-hydrated UI and the intended UI.
 *`
 * To avoid this, we will use this hook to replace useLayoutEffect.
 */
const useSSRLayoutEffect = isClient ? useLayoutEffect : useEffect;

export default useSSRLayoutEffect;
