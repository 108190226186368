import React from 'react';

const TwitterLogo = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
    <defs />
    <path d='M512 97.25a218.84 218.84 0 01-60.48 16.57c21.76-12.99 38.37-33.4 46.18-58.01a209.77 209.77 0 01-66.56 25.4A105.18 105.18 0 00354.46 48c-58.1 0-104.9 47.17-104.9 105 0 8.31.71 16.31 2.44 23.93-87.26-4.26-164.48-46.08-216.35-109.8a105.68 105.68 0 00-14.37 53.06c0 36.35 18.72 68.58 46.62 87.23-16.86-.32-33.4-5.21-47.42-12.92v1.15c0 51 36.38 93.37 84.1 103.13a104.76 104.76 0 01-27.52 3.46c-6.72 0-13.5-.38-19.88-1.8 13.6 41.58 52.2 72.14 98.08 73.13a210.9 210.9 0 01-130.14 44.77c-8.6 0-16.86-.39-25.12-1.44C46.5 446.88 101.6 464 161.02 464c193.16 0 298.76-160 298.76-298.69 0-4.64-.16-9.12-.39-13.57 20.83-14.78 38.34-33.24 52.61-54.5z' />
  </svg>
);

export default TwitterLogo;
