import React from 'react';

const InstagramLogo = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 511 511.9' {...props}>
    <defs />
    <path d='M510.95 150.5c-1.2-27.2-5.6-45.9-11.9-62.1-6.5-17.2-16.5-32.6-29.6-45.4-12.8-13-28.3-23.1-45.3-29.5-16.3-6.3-34.9-10.7-62.1-11.9C334.65.3 325.95 0 256.45 0s-78.2.3-105.5 1.5c-27.2 1.2-45.9 5.6-62.1 11.9A124.9 124.9 0 0043.45 43c-13 12.8-23.1 28.3-29.5 45.3-6.3 16.3-10.7 34.9-11.9 62.1C.75 177.8.45 186.5.45 256s.3 78.2 1.5 105.5c1.2 27.2 5.6 45.9 11.9 62.1 6.5 17.2 16.6 32.6 29.6 45.4 12.8 13 28.3 23.1 45.3 29.5 16.3 6.3 34.9 10.7 62.1 11.9 27.3 1.2 36 1.5 105.5 1.5s78.2-.3 105.5-1.5c27.2-1.2 45.9-5.6 62.1-11.9 34.4-13.3 61.6-40.5 74.9-74.9 6.3-16.3 10.7-34.9 11.9-62.1 1.2-27.3 1.5-36 1.5-105.5s-.1-78.2-1.3-105.5zm-46.1 209c-1.1 25-5.3 38.5-8.8 47.5-8.6 22.3-26.3 40-48.6 48.6-9 3.5-22.6 7.7-47.5 8.8-27 1.2-35.1 1.5-103.4 1.5s-76.5-.3-103.4-1.5c-25-1.1-38.5-5.3-47.5-8.8a78.77 78.77 0 01-29.4-19.1c-8.5-8.3-15-18.3-19.1-29.4-3.5-9-7.7-22.6-8.8-47.5-1.2-27-1.5-35.1-1.5-103.4s.3-76.5 1.5-103.4c1.1-25 5.3-38.5 8.8-47.5a77.8 77.8 0 0119.2-29.4c8.3-8.5 18.3-15 29.4-19.1 9-3.5 22.6-7.7 47.5-8.8 27-1.2 35.1-1.5 103.4-1.5 68.4 0 76.5.3 103.4 1.5 25 1.1 38.5 5.3 47.5 8.8a78.71 78.71 0 0129.4 19.1c8.5 8.3 15 18.3 19.1 29.4 3.5 9 7.7 22.6 8.8 47.5 1.2 27 1.5 35.1 1.5 103.4s-.3 76.3-1.5 103.3zm0 0' />
    <path d='M256.45 124.5c-72.6 0-131.5 58.9-131.5 131.5s58.9 131.5 131.5 131.5 131.5-58.9 131.5-131.5-58.9-131.5-131.5-131.5zm0 216.8c-47.1 0-85.3-38.2-85.3-85.3s38.2-85.3 85.3-85.3a85.31 85.31 0 010 170.6zm0 0M423.85 119.3a30.7 30.7 0 11-61.4 0 30.7 30.7 0 0161.4 0zm0 0' />
  </svg>
);

export default InstagramLogo;
