import React from 'react';

const RiotFistLogo = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width='268' height='245' viewBox='0 0 268 245' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M186.8 6.5L161.9 0L120.2 18.6L56.5 47L0 72.2L18.2 142.2L35.8 210.2L57 207.6L67.2 206.3L60.8 141.5L60.4 137.8L58.6 118.9L67.6 113.7L68.3 113.2L71.5 131.4L74.8 149.6L84.5 204.2L85.5 204.1L132.2 198.3L133.3 198.2L128.4 148.3L125.6 120.3L123.7 101.3L133.9 95.4V95.5L137.1 113.6L139.7 128.1L151.8 195.9L200.5 190L201.3 189.9L199 166.5L190.7 82.7L193.8 80.9L199.1 77.9L199.4 77.6L219 187.7L247.3 184.2L267.3 181.8V38.5V27.9L186.8 6.5Z'
      fill='#F5F5F5'
    />
    <path d='M221.9 204.1L171.5 210.3L154.7 212.3L157 225.3L228 237.8L267.3 244.8V198.5L247.3 200.9L221.9 204.1Z' fill='#F5F5F5' />
  </svg>
);

export default RiotFistLogo;
