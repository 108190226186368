import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import React, { FC, ReactNode, useState } from 'react';
import useSSRLayoutEffect from '../../../hooks/useSSRLayoutEffect';
import { Cookie, FooterTxt, MenuTxt } from '../../../interfaces/ui-elements.interface';
import RTCookieBanner from '../cookieBanner/cookieBanner';
import RTFooter from '../footer/footer';
import RTHeader from '../header/header';
import LanguageHelmet from '../languages/language-helmet';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

type RTLayoutProps = {
  children: ReactNode;
  menuText: MenuTxt;
  footerText: FooterTxt;
  cookie: Cookie;
  langModalTranslations: {
    title: string;
    subtitle: string;
  };
};

const RTLayout: FC<RTLayoutProps> = ({ children, menuText, footerText, cookie, langModalTranslations }) => {
  const [showCookieBanner, setShowCookieBanner] = useState<boolean>(false);

  useSSRLayoutEffect(() => {
    const cookieConsent = localStorage.getItem('RT_CookieConsent');

    if (cookieConsent === null) {
      setShowCookieBanner(true);
    } else {
      setShowCookieBanner(false);
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <LanguageHelmet />
        <RTHeader menuTxt={menuText} />
        <main>{children}</main>
        <RTFooter footerTxt={footerText} langModalTranslations={langModalTranslations} />
        {showCookieBanner && <RTCookieBanner cookie={cookie} setShowCookieBanner={setShowCookieBanner} />}
      </ThemeProvider>
    </>
  );
};

export default RTLayout;
