import React from 'react';

const TriangleLogo = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' {...props}>
    <defs />
    <path d='M50 15l50 85H0z' />
  </svg>
);

export default TriangleLogo;
