import React, { Dispatch, FC, SetStateAction } from 'react';
import { Cookie } from '../../../interfaces/ui-elements.interface';
import * as styles from './cookieBanner.module.scss';

type RTCookieBannerProps = {
  cookie: Cookie;
  setShowCookieBanner: Dispatch<SetStateAction<boolean>>;
};

const RTCookieBanner: FC<RTCookieBannerProps> = ({ cookie, setShowCookieBanner }) => {
  const acceptCookies = () => {
    localStorage.setItem('RT_CookieConsent', 'true');
    setShowCookieBanner(false);
  };

  const rejectCookies = () => {
    localStorage.setItem('RT_CookieConsent', 'false');
    setShowCookieBanner(false);
  };

  return (
    <div className='cookie fixed text-xs z-20 w-full bottom-0 left-0 right-0 py-8 lg:py-4 px-4 flex flex-col lg:flex-row lg:justify-between items-center space-y-8 lg:space-y-0 lg:space-x-6 bg-primary text-white'>
      <div
        className={`${styles.content} max-w-4xl`}
        dangerouslySetInnerHTML={{
          __html: cookie.cookieContent,
        }}
      />
      <div className='flex flex-col space-y-4 w-full md:w-auto'>
        <button onClick={acceptCookies} className={`bg-white px-8 w-full md:w-60 py-2 whitespace-nowrap text-primary font-bold`}>
          Accept
        </button>
        <button onClick={rejectCookies} className={`bg-white px-8 w-full md:w-60 py-2 whitespace-nowrap text-primary font-bold`}>
          Decline
        </button>
      </div>
    </div>
  );
};

export default RTCookieBanner;
