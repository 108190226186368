import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import gsap from 'gsap';
import React, { FC, useMemo, useRef, useState } from 'react';
import RiotFistTextLogo from '../../../assets/icon/riot-fist-text.svg';
import useSSRLayoutEffect from '../../../hooks/useSSRLayoutEffect';
import { MenuTxt } from '../../../interfaces/ui-elements.interface';
import { getLangFullPathname, getLangPath } from '../languages/languageUtils';
import * as styles from './header.module.scss';

type RTHeader = {
  menuTxt: MenuTxt;
};

const RTHeader: FC<RTHeader> = ({ menuTxt }) => {
  const location = useLocation();
  const [currentLang, setCurrentLang] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const linkListRef = useRef<HTMLUListElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [timeline] = useState(() => gsap.timeline({ paused: true, defaults: { duration: 1, delay: 0, ease: 'Power3.InOut' } }));
  const links = [
    { to: '/', display: 'Home', isDisplayDesktop: false },
    { to: '/#games', display: menuTxt.games || 'Games', isDisplayDesktop: true },
    { to: '/#faq', display: menuTxt.faq || 'FAQ', isDisplayDesktop: true },
    { to: '/calendar', display: menuTxt.calendar || 'Calendar', isDisplayDesktop: true },
  ];

  const toggleMenu = (): void => {
    setIsMenuOpen(boolean => !boolean);

    if (isMenuOpen) {
      timeline.timeScale(2).reverse();
    } else {
      timeline.play();
    }
  };

  useSSRLayoutEffect(() => {
    const currentLang = localStorage.getItem('RT_current_lang');

    if (currentLang) {
      setCurrentLang((JSON.parse(currentLang) as { value: string }).value);
    }
  }, []);

  useSSRLayoutEffect(() => {
    const onScroll = () => {
      setIsScrolled(window.scrollY > 150);
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
      setIsScrolled(false);
    };
  }, []);

  useSSRLayoutEffect(() => {
    timeline.to(menuRef.current, {
      y: 0,
      duration: 0.6,
    });
    timeline.to(gsap.utils.selector(linkListRef.current)('a'), {
      y: 0,
      stagger: {
        amount: 0.2,
      },
    });
  }, []);

  const isLogoBlack = useMemo(() => {
    const GAME_PATH = '/games/valorant-console';
    const GAME_GUIDELINE_PATH = '/games/valorant-console/guidelines';

    if (location.pathname.includes(GAME_GUIDELINE_PATH)) return false;
    else if (location.pathname.includes(GAME_PATH)) return true;
    else return false;
  }, [location.pathname]);

  return (
    <>
      <header className={`${styles.rtHeader} transition-colors duration-300 ${isScrolled ? 'bg-primary' : 'bg-transparent'}`}>
        <div className={`w-full max-w-screen-2xl mx-auto px-4 md:px-8 xl:px-12`}>
          <div className='flex justify-between items-center'>
            <Link to={getLangPath(currentLang)}>
              <RiotFistTextLogo className='w-16 md:w-24' fill={isLogoBlack && !isScrolled ? '#000' : '#fff'} />
            </Link>
            <button type='button' title='Menu' className={`${styles.burger} ${isMenuOpen ? styles.burgerOpen : ''}`} onClick={toggleMenu}>
              <span className={`${styles.burgerLine} ${isLogoBlack && !isScrolled ? styles.burgerLineBlack : ''}`} />
              <span className={`${styles.burgerLine} ${isLogoBlack && !isScrolled ? styles.burgerLineBlack : ''}`} />
              <span className={`${styles.burgerLine} ${isLogoBlack && !isScrolled ? styles.burgerLineBlack : ''}`} />
            </button>
            <ul className='hidden lg:flex lg:space-x-20'>
              {links
                .filter(link => link.isDisplayDesktop)
                .map((link, index) => (
                  <li key={index}>
                    <Link className={styles.link} to={getLangFullPathname(currentLang, link.to, true, currentLang !== '')}>
                      {link.display}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </header>
      <div
        ref={menuRef}
        className={`${styles.menu} fixed lg:hidden inset-0 bg-primary py-32 px-4 md:px-8 xl:px-12 transform -translate-y-full`}
      >
        <ul ref={linkListRef} className='space-y-12'>
          {links.map((link, index) => (
            <li key={index} className='overflow-hidden'>
              <Link
                className='uppercase text-white inline-block transform translate-y-full'
                to={getLangFullPathname(currentLang, link.to, true, currentLang !== '')}
              >
                {link.display}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default RTHeader;
