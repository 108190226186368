import React from 'react';

const Close = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    stroke='currentColor'
    fill='currentColor'
    strokeWidth='0'
    viewBox='0 0 24 24'
    height='1em'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path fill='none' stroke='inherit' strokeWidth='2' d='M3,3 L21,21 M3,21 L21,3'></path>
  </svg>
);

export default Close;
