import React from 'react';

const WorldLogo = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.7 22' {...props}>
    <defs />
    <path d='M22.7 10.8c0-2.9-1.3-5.6-3.4-7.7-2.1-2-5-3.2-8-3.2h-.2C4.9.1-.1 5.1 0 11.2c0 2.9 1.3 5.6 3.4 7.7 2.1 2 5 3.2 8 3.2h.2c3 0 5.9-1.2 8-3.3 2-2.3 3.1-5 3.1-8zm-1.9 4h-2.5c.3-1.1.4-2.3.4-3.5h3c0 1.3-.3 2.5-.8 3.6l-.1-.1zm-18.9.4c-.5-1.1-.8-2.3-.9-3.6h3c.1 1.2.2 2.4.5 3.5H2s-.1 0-.1.1zm0-8.4h2.6C4.1 8 4 9.3 4 10.7H1c0-1.4.3-2.7.9-3.9zm18.8-.3c.6 1.2 1 2.5 1 3.9h-3c-.1-1.4-.3-2.6-.6-3.8h2.4c.1 0 .1 0 .2-.1zm-2.9 4l-6 .1-.1-3.8 5.4-.1c.4 1.1.6 2.4.7 3.8zm-6.1-4.7L11.6.9c2.2.2 4.1 2.1 5.2 4.8l-5.1.1zm-1-4.9l.1 4.9-5.1.1c1-2.8 2.9-4.7 5-5zm.1 5.8l.1 3.8-6 .1c0-1.4.2-2.7.5-3.8l5.4-.1zm-5.9 4.8l6-.1.1 3.5-5.5.1c-.3-1-.5-2.2-.6-3.5zm6.1 4.4l.1 5.2c-2.2-.2-4.2-2.2-5.3-5.1l5.2-.1zm1 5.2l-.1-5.2 5.2-.1c-1 2.9-2.9 5-5.1 5.3zm-.1-6.1l-.1-3.5 6-.1c0 1.3-.2 2.4-.5 3.5l-5.4.1zm8.3-9.3h-2.4c-.6-1.7-1.6-3.1-2.7-4.1 2.1.8 3.9 2.2 5.1 4.1zm-12.9-4c-.5.5-1 1.1-1.4 1.8-.5.7-.9 1.5-1.2 2.4H2.3c1.1-1.8 2.9-3.3 5-4.2zM2.3 16h2.5c.6 1.8 1.6 3.4 2.8 4.4-2.3-.8-4.1-2.4-5.3-4.4zm13.1 4.3c.5-.5 1-1.1 1.4-1.8.5-.8.9-1.8 1.2-2.8h2.5c-1.1 2.1-2.9 3.7-5.1 4.6z' />
  </svg>
);

export default WorldLogo;
