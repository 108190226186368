import { useLocation } from '@reach/router';
import React, { useEffect } from 'react';
import { isBrowser } from '../../../utils';
import { languageRegex, supportedLang } from './languageUtils';

const LanguageHelmet = () => {
  const location = useLocation();

  useEffect(() => {
    if (!isBrowser) return;

    const browserLang = navigator.language;
    const pathname = location.pathname;

    const getLangFromPathname = () => {
      const match = languageRegex(true).exec(pathname);

      if (match) {
        const filter = supportedLang.find(lang => lang.value === match[0].replace(/\/+/g, ''));
        return filter ?? { displayName: 'English', value: '' };
      } else {
        return { displayName: 'English', value: '' };
      }
    };

    const lang = getLangFromPathname();

    // Define browser lang
    if (lang.value === browserLang.substring(0, 2) || lang.value === '') {
      localStorage.setItem(
        'RT_browser_lang',
        JSON.stringify({
          displayName: supportedLang.find(lang => lang.value === browserLang.substring(0, 2))?.displayName || 'English',
          value: browserLang.substring(0, 2) === 'en' ? '' : browserLang.substring(0, 2),
        }),
      );
    }

    // Define current lang in localStorage
    localStorage.setItem(
      'RT_current_lang',
      JSON.stringify({
        displayName: lang.displayName,
        value: lang.value,
      }),
    );
  }, []);

  return <></>;
};

export default LanguageHelmet;
