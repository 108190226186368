export const supportedLang = [
  { language: 'en', value: '', displayName: 'English' },
  { language: 'fr', value: 'fr', displayName: 'Français' },
  { language: 'it', value: 'it', displayName: 'Italiano' },
  { language: 'es', value: 'es', displayName: 'Español' },
  { language: 'de', value: 'de', displayName: 'Deutsch' },
  { language: 'cs', value: 'cs', displayName: 'Čeština' },
  { language: 'hu', value: 'hu', displayName: 'Magyar' },
  { language: 'pl', value: 'pl', displayName: 'Polskie' },
  { language: 'tr', value: 'tr', displayName: 'Türk' },
  { language: 'ru', value: 'ru', displayName: 'русский' },
  { language: 'gr', value: 'gr', displayName: 'Ελληνικά' },
  { language: 'ro', value: 'ro', displayName: 'Română' },
];

export const languageRegex = (hasSlash = false) =>
  new RegExp(
    supportedLang
      .map(lang => {
        if (lang.value !== '') {
          return `${hasSlash ? '/' : ''}${lang.value}`;
        }
      })
      .join('|')
      .substring(1),
  );

export const getLangFullPathname = (language: string, pathname: string, removeLang = false, isDefaultLang = false) => {
  if (removeLang) {
    return `${getLangPath(language)}${isDefaultLang ? '/' : ''}${removeLangPrefix(pathname)}`;
  }
  return `${getLangPath(language)}${pathname}`;
};

export const removeLangPrefix = (pathname: string): string => {
  if (languageRegex(true).test(pathname)) {
    return pathname.replace(languageRegex(true), '');
  } else {
    return pathname.replace(/\/+/, '');
  }
};

export const getLangPath = (language: string) => {
  if (language !== '') {
    return `/${language}`;
  } else {
    return '/';
  }
};
