import { getContrast } from 'polished';

export const isBrowser = typeof window !== 'undefined';

export const isClient = typeof window !== 'undefined';

export const getContrastYIQ = (bgColor: string, contrast = 3) => {
  const contrastRatio = getContrast(bgColor, '#fff');

  return contrastRatio <= contrast ? '#000' : '#fff';
};
