import React, { FC, useState } from 'react';
import RiotFistLogo from '../../../assets/icon/riot-fist.svg';
import FacebookLogo from '../../../assets/icon/socials/facebook.svg';
import InstagramLogo from '../../../assets/icon/socials/instagram.logo';
import TwitterLogo from '../../../assets/icon/socials/twitter.svg';
import TriangleLogo from '../../../assets/icon/triangle.svg';
import WorldLogo from '../../../assets/icon/world.svg';
import useSSRLayoutEffect from '../../../hooks/useSSRLayoutEffect';
import { FooterTxt } from '../../../interfaces/ui-elements.interface';
import LanguagueDialog from '../languages/language-dialog/language-dialog';
import * as styles from './footer.module.scss';

const socials = [
  { name: 'Facebook', url: 'https://facebook.com/RiotGames', Icon: FacebookLogo },
  { name: 'Twitter', url: 'https://twitter.com/riotgames', Icon: TwitterLogo },
  { name: 'Instagram', url: 'https://instagram.com/riotgames', Icon: InstagramLogo },
];

type RTFooterProps = {
  footerTxt: FooterTxt;
  langModalTranslations: {
    title: string;
    subtitle: string;
  };
};

const RTFooter: FC<RTFooterProps> = ({ footerTxt, langModalTranslations }) => {
  const [open, setOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState('');
  const [selectedValue, setSelectedValue] = useState({ displayName: 'English', value: '' });

  const scrollToTheTop = (): void => {
    scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleOpenDialogClick = (): void => {
    setOpen(true);
  };

  const handleClose = (value: { displayName: string; value: string }) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useSSRLayoutEffect(() => {
    const browserLang = localStorage.getItem('RT_browser_lang');
    const currentLang = localStorage.getItem('RT_current_lang');

    if (currentLang !== null) {
      const parsedCurrentLang = JSON.parse(currentLang) as { displayName: string; value: string };
      setSelectedValue(parsedCurrentLang);
      setCurrentLang((JSON.parse(currentLang) as { value: string }).value);
    } else if (browserLang !== null) {
      const parsedBrowserLang = JSON.parse(browserLang) as { displayName: string; value: string };
      setSelectedValue(parsedBrowserLang);
    }
  }, []);

  return (
    <footer className='relative z-10 text-white bg-grey-dark py-14'>
      <div className='w-full max-w-screen-2xl mx-auto px-4 md:px-8 xl:px-12'>
        <div className='flex flex-col md:flex-row justify-between items-center space-y-12 md:space-y-0'>
          <div className='flex-1'>
            <RiotFistLogo height={34} width={34} fill='white' />
          </div>
          <div
            className={`flex-3 flex flex-col justify-center md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8 text-center ${styles.footerLinks}`}
          >
            <a target='_blank' href='https://www.riotgames.com/en/legal' rel='noreferrer'>
              {footerTxt.legal || 'Legal'}
            </a>
            <a target='_blank' href='https://www.riotgames.com/en/terms-of-service' rel='noreferrer'>
              {footerTxt.termsOfService || 'Terms of Service'}
            </a>
            <a target='_blank' href='https://www.riotgames.com/en/privacy-notice' rel='noreferrer'>
              {footerTxt.privacyNotice || 'Privacy Notice'}
            </a>
            <a target='_blank' href='https://www.competitiveops.eu/' rel='noreferrer'>
              {footerTxt.competitiveRulings || 'Competitive rulings'}
            </a>
          </div>
          <ul className='flex-1 flex justify-end space-x-6'>
            {socials.map((social, index) => (
              <li key={index}>
                <a href={social.url} target='_blank' rel='noopener noreferrer'>
                  <social.Icon width={20} height={20} fill='white' />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <hr className='border-white my-10' />
        <div className='flex flex-col items-center md:flex-row md:justify-between space-y-8 md:space-y-0'>
          <button className='flex items-center space-x-2' onClick={handleOpenDialogClick}>
            <WorldLogo height={20} width={20} fill='white' />
            <p className='font-MarkSCPro font-medium text-sm'>{selectedValue.displayName} +</p>
          </button>
          <LanguagueDialog selectedValue={selectedValue} open={open} onClose={handleClose} langModalTranslations={langModalTranslations} />
          <p className='text-sm order-2 md:order-none'>
            © {new Date().getFullYear()} Riot Games. {footerTxt.allRightsReserved || 'All rights Reserved.'}
          </p>
          <button className='flex items-center space-x-6' onClick={scrollToTheTop}>
            <p className='text-sm'>{footerTxt.toTheSurface || 'To the surface'}</p>
            <TriangleLogo height={12} width={12} fill='white' />
          </button>
        </div>
      </div>
    </footer>
  );
};

export default RTFooter;
