import { useMediaQuery } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import Close from '../../../../assets/icon/Close';
import { getLangFullPathname, languageRegex, supportedLang } from '../languageUtils';
import * as dialogStyle from './languague-dialog.module.scss';

export const LanguageDialog: FC<{
  onClose: (value: { displayName: string; value: string }) => any;
  open: boolean;
  selectedValue: { displayName: string; value: string };
  langModalTranslations: { title: string; subtitle: string };
}> = ({ onClose, selectedValue, open, langModalTranslations }) => {
  const fullScreen = useMediaQuery('(max-width:600px)');

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (selectedLang: { displayName: string; value: string }) => {
    const currentLang = JSON.parse(localStorage.getItem('RT_current_lang') || '{}');
    localStorage.setItem('RT_preferred_lang', JSON.stringify({ displayName: selectedLang.displayName, value: selectedLang.value }));
    if (selectedLang.value === '') {
      navigate(location.pathname.replace(languageRegex(true), ''));
    } else {
      if (currentLang.value === '') {
        navigate(getLangFullPathname(selectedLang.value || '', location.pathname, true, true));
      } else {
        navigate(getLangFullPathname(selectedLang.value || '', location.pathname, true));
      }
    }

    scrollTo(0, 0);
    onClose(selectedLang);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby='language-dialog' open={open} fullScreen={fullScreen}>
      <div className={dialogStyle.dialogContainer}>
        <h2>{langModalTranslations.title || 'Language selector'}</h2>
        <p>{langModalTranslations.subtitle || 'Select your language'}</p>
        <div className={dialogStyle.langsListContainer}>
          {supportedLang.map((lang, idx) => (
            <div key={idx} className={dialogStyle.lang} onClick={() => handleListItemClick(lang)}>
              <span className={dialogStyle.underline}>{lang.displayName}</span>
            </div>
          ))}
        </div>
        <button className={dialogStyle.closeButton} onClick={handleClose}>
          <Close />
        </button>
      </div>
    </Dialog>
  );
};

export default LanguageDialog;
